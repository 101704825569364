[
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt1/pdf/CRPT-118hrpt1.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE JOINT RESOLUTION (H.J. RES. 7) RELATING TO A NATIONAL EMERGENCY DECLARED BY THE PRESIDENT ON MARCH 13, 2020; PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 139) TO REQUIRE EXECUTIVE AGENCIES TO SUBMIT TO CONGRESS A STUDY OF THE IMPACTS OF EXPANDED TELEWORK AND REMOTE WORK BY AGENCY EMPLOYEES DURING THE COVID- 19 PANDEMIC AND A PLAN FOR THE AGENCY'S FUTURE USE OF TELEWORK AND REMOTE WORK, AND FOR OTHER PURPOSES; PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 382) TO TERMINATE THE PUBLIC HEALTH EMERGENCY DECLARED WITH RESPECT TO COVID-19; AND PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 497) TO ELIMINATE THE COVID-19 VACCINE MANDATE ON HEALTH CARE PROVIDERS FURNISHING ITEMS AND SERVICES UNDER CERTAIN FEDERAL HEALTH CARE",
    "report_committee": "Committee on Rules",
    "report_publish_date": "January 31, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt2",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt2/pdf/CRPT-118hrpt2.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE CONCURRENT RESOLUTION (H. CON. RES. 9) DENOUNCING THE HORRORS OF SOCIALISM, AND PROVIDING FOR CONSIDERATION OF THE RESOLUTION (H. RES. 76) REMOVING A CERTAIN MEMBER FROM A CERTAIN STANDING COMMITTEE OF THE HOUSE",
    "report_committee": "Committee on Rules",
    "report_publish_date": "February 1, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt3",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt3/pdf/CRPT-118hrpt3.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 185) TO TERMINATE THE REQUIREMENT IMPOSED BY THE DIRECTOR OF THE CENTERS FOR DISEASE CONTROL AND PREVENTION FOR PROOF OF COVID-19 VACCINATION FOR FOREIGN TRAVELERS, AND FOR OTHER PURPOSES; PROVIDING FOR CONSIDERATION OF THE JOINT RESOLUTION (H.J. RES. 24) DISAPPROVING THE ACTION OF THE DISTRICT OF COLUMBIA COUNCIL IN APPROVING THE LOCAL RESIDENT VOTING RIGHTS AMENDMENT ACT OF 2022; AND PROVIDING FOR CONSIDERATION OF THE JOINT RESOLUTION (H.J. RES. 26) DISAPPROVING THE ACTION OF THE DISTRICT OF COLUMBIA COUNCIL IN APPROVING THE REVISED CRIMINAL CODE ACT OF 2022",
    "report_committee": "Committee on Rules",
    "report_publish_date": "February 7, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt4",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt4/pdf/CRPT-118hrpt4.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 347) TO REQUIRE THE EXECUTIVE OFFICE OF THE PRESIDENT TO PROVIDE AN INFLATION ESTIMATE WITH RESPECT TO EXECUTIVE ORDERS WITH A SIGNIFICANT EFFECT ON THE ANNUAL GROSS BUDGET, AND FOR OTHER PURPOSES, AND PROVIDING FOR CONSIDERATION OF THE JOINT RESOLUTION (H.J. RES. 30) PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE DEPARTMENT OF LABOR RELATING TO ``PRUDENCE AND LOYALTY IN SELECTING PLAN INVESTMENTS AND EXERCISING SHAREHOLDER RIGHTS''",
    "report_committee": "Committee on Rules",
    "report_publish_date": "February 27, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt5",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt5/pdf/CRPT-118hrpt5.pdf",
    "report_title": "PROTECTING SPEECH FROM GOVERNMENT INTERFERENCE ACT",
    "report_committee": "Committee on Oversight",
    "report_publish_date": "March 2, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt6-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt6/pdf/CRPT-118hrpt6-pt1.pdf",
    "report_title": "PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE DEPARTMENT OF THE ARMY, CORPS OF ENGINEERS, DEPARTMENT OF DEFENSE AND THE ENVIRONMENTAL PROTECTION AGENCY RELATING TO ``REVISED DEFINITION OF `WATERS OF THE UNITED STATES'''",
    "report_committee": "Committee on Transportation and Infrastructure",
    "report_publish_date": "March 3, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt7",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt7/pdf/CRPT-118hrpt7.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 140) TO AMEND TITLE 5, UNITED STATES CODE, TO PROHIBIT FEDERAL EMPLOYEES FROM ADVOCATING FOR CENSORSHIP OF VIEWPOINTS IN THEIR OFFICIAL CAPACITY, AND FOR OTHER PURPOSES; PROVIDING FOR CONSIDERATION OF THE JOINT RESOLUTION (H.J. RES. 27) PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE DEPARTMENT OF THE ARMY, CORPS OF ENGINEERS, DEPARTMENT OF DEFENSE AND THE ENVIRONMENTAL PROTECTION AGENCY RELATING TO ``REVISED DEFINITION OF `WATERS OF THE UNITED STATES'''; AND PROVIDING FOR CONSIDERATION OF THE BILL (S. 619) TO REQUIRE THE DIRECTOR OF NATIONAL INTELLIGENCE TO DECLASSIFY INFORMATION RELATING TO THE ORIGIN OF COVID-19, AND FOR",
    "report_committee": "Committee on Rules",
    "report_publish_date": "March 7, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt8",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt8/pdf/CRPT-118hrpt8.pdf",
    "report_title": "PROVIDING FOR THE EXPENSES OF CERTAIN COMMITTEES OF THE HOUSE OF REPRESENTATIVES IN THE ONE HUNDRED EIGHTEENTH CONGRESS",
    "report_committee": "Committee on House Administration",
    "report_publish_date": "March 8, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt9",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt9/pdf/CRPT-118hrpt9.pdf",
    "report_title": "PARENTS BILL OF RIGHTS ACT",
    "report_committee": "Committee on Education and the Workforce",
    "report_publish_date": "March 14, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt10",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt10/pdf/CRPT-118hrpt10.pdf",
    "report_title": "WATER QUALITY CERTIFICATION AND ENERGY PROJECT IMPROVEMENT ACT OF 2023",
    "report_committee": "Committee on Transportation and Infrastructure",
    "report_publish_date": "March 17, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt11",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt11/pdf/CRPT-118hrpt11.pdf",
    "report_title": "RESOLUTION AUTHORIZING THE USE OF THE CAPITOL GROUNDS FOR THE NATIONAL PEACE OFFICERS MEMORIAL SERVICE AND THE NATIONAL HONOR GUARD AND PIPE BAND EXHIBITION",
    "report_committee": "Committee on Transportation and Infrastructure",
    "report_publish_date": "March 17, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt12",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt12/pdf/CRPT-118hrpt12.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 5) TO ENSURE THE RIGHTS OF PARENTS ARE HONORED AND PROTECTED IN THE NATION'S PUBLIC SCHOOLS",
    "report_committee": "Committee on Rules",
    "report_publish_date": "March 22, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt13",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt13/pdf/CRPT-118hrpt13.pdf",
    "report_title": "KEEPING AMERICA'S REFINERIES ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt14",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt14/pdf/CRPT-118hrpt14.pdf",
    "report_title": "ELIMINATION OF FUTURE TECHNOLOGY DELAYS ACT OF 2023",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt15",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt15/pdf/CRPT-118hrpt15.pdf",
    "report_title": "NATURAL GAS TAX REPEAL ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt16",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt16/pdf/CRPT-118hrpt16.pdf",
    "report_title": "TO AUTHORIZE THE ADMINISTRATOR OF THE ENVIRONMENTAL PROTECTION AGENCY TO WAIVE APPLICATION OF CERTAIN REQUIREMENTS WITH RESPECT TO PROCESSING AND REFINING A CRITICAL ENERGY RESOURCE AT A CRITICAL ENERGY RESOURCE FACILITY, AND FOR OTHER PURPOSES",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt17",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt17/pdf/CRPT-118hrpt17.pdf",
    "report_title": "TO REQUIRE THE ADMINISTRATOR OF THE ENVIRONMENTAL PROTECTION AGENCY TO AUTHORIZE THE USE OF FLEXIBLE AIR PERMITTING WITH RESPECT TO CERTAIN CRITICAL ENERGY RESOURCE FACILITIES, AND FOR OTHER PURPOSES",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt18",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt18/pdf/CRPT-118hrpt18.pdf",
    "report_title": "UNLOCKING OUR DOMESTIC LNG POTENTIAL ACT OF 2023",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt19-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt19/pdf/CRPT-118hrpt19-pt1.pdf",
    "report_title": "PROTECTING AMERICAN ENERGY PRODUCTION ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt20",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt20/pdf/CRPT-118hrpt20.pdf",
    "report_title": "RESEARCHING EFFICIENT FEDERAL IMPROVEMENTS FOR NECESSARY ENERGY REFINING ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt21",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt21/pdf/CRPT-118hrpt21.pdf",
    "report_title": "TO AMEND THE SOLID WASTE DISPOSAL ACT TO PROVIDE THE OWNER OR OPERATOR OF A CRITICAL ENERGY RESOURCE FACILITY AN INTERIM PERMIT UNDER SUBTITLE C THAT IS SUBJECT TO FINAL APPROVAL BY THE ADMINISTRATOR OF THE ENVIRONMENTAL PROTECTION AGENCY, AND FOR OTHER PURPOSES",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt22",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt22/pdf/CRPT-118hrpt22.pdf",
    "report_title": "SECURING AMERICA'S CRITICAL MINERALS SUPPLY ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt23",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt23/pdf/CRPT-118hrpt23.pdf",
    "report_title": "PROMOTING INTERAGENCY COORDINATION FOR REVIEW OF NATURAL GAS PIPELINES ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt24-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt24/pdf/CRPT-118hrpt24-pt1.pdf",
    "report_title": "PROMOTING CROSS-BORDER ENERGY INFRASTRUCTURE ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt25-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt25/pdf/CRPT-118hrpt25-pt1.pdf",
    "report_title": "RESOLUTION EXPRESSING DISAPPROVAL OF THE REVOCATION BY PRESI - DENT BIDEN OF THE PRESIDENTIAL PERMIT FOR THE KEYSTONE XL PIPELINE",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt26",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt26/pdf/CRPT-118hrpt26.pdf",
    "report_title": "TO REPEAL SECTION 134 OF THE CLEAN AIR ACT, RELATING TO THE GREENHOUSE GAS REDUCTION FUND",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt27",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt27/pdf/CRPT-118hrpt27.pdf",
    "report_title": "EXPRESSING THE SENSE OF CONGRESS THAT THE FEDERAL GOVERNMENT SHOULD NOT IMPOSE ANY RESTRICTIONS ON THE EXPORT OF CRUDE OIL OR OTHER PETROLEUM PRODUCTS",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt28-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt28/pdf/CRPT-118hrpt28-pt1.pdf",
    "report_title": "TRANSPARENCY, ACCOUNTABILITY, PERMITTING, AND PRODUCTION OF AMERICAN RESOURCES ACT",
    "report_committee": "Committee on Natural Resources",
    "report_publish_date": "March 23, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt29",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt29/pdf/CRPT-118hrpt29.pdf",
    "report_title": "RESOLUTION OF INQUIRY REQUESTING THE PRESIDENT AND DIRECTING THE SECRETARY OF DEFENSE AND SECRETARY OF STATE TO TRANSMIT, RESPECTIVELY, CERTAIN DOCUMENTS TO THE HOUSE OF REPRESENTATIVES RELATING TO CONGRESSIONALLY APPROPRIATED FUNDS TO THE NATION OF UKRAINE FROM JANUARY 20, 2021 TO FEBRUARY 24, 2023",
    "report_committee": "Committee on Foreign Affairs",
    "report_publish_date": "March 27, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt30",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt30/pdf/CRPT-118hrpt30.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 1) TO LOWER ENERGY COSTS BY INCREASING AMERICAN ENERGY PRODUCTION, EXPORTS, INFRASTRUCTURE, AND CRITICAL MINERALS PROCESSING, BY PROMOTING TRANSPARENCY, ACCOUNTABILITY, PERMITTING, AND PRODUCTION OF AMERICAN RESOURCES, AND BY IMPROVING WATER QUALITY CERTIFICATION AND ENERGY PROJECTS, AND FOR OTHER PURPOSES",
    "report_committee": "Committee on Rules",
    "report_publish_date": "March 28, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt31",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt31/pdf/CRPT-118hrpt31.pdf",
    "report_title": "HOMEOWNER ENERGY FREEDOM ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "March 28, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt32-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt32/pdf/CRPT-118hrpt32-pt1.pdf",
    "report_title": "COVID-19 ORIGIN ACT OF 2023",
    "report_committee": "Permanent Select Committee on Intelligence",
    "report_publish_date": "March 30, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt33",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt33/pdf/CRPT-118hrpt33.pdf",
    "report_title": "DISAPPROVING THE ACTION OF THE DISTRICT OF COLUMBIA COUNCIL IN APPROVING THE COMPREHENSIVE POLICING AND JUSTICE REFORM AMENDMENT ACT OF 2022",
    "report_committee": "Committee on Oversight and Accountability",
    "report_publish_date": "April 6, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt34",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt34/pdf/CRPT-118hrpt34.pdf",
    "report_title": "PROTECTING TAXPAYERS AND VICTIMS OF UNEMPLOYMENT FRAUD ACT",
    "report_committee": "Committee on Ways and Means",
    "report_publish_date": "April 6, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt35",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt35/pdf/CRPT-118hrpt35.pdf",
    "report_title": "PROTECTION OF WOMEN AND GIRLS IN SPORTS ACT OF 2023",
    "report_committee": "Committee on Education and the Workforce",
    "report_publish_date": "April 10, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt36",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt36/pdf/CRPT-118hrpt36.pdf",
    "report_title": "AUTHORIZATION AND OVERSIGHT PLANS FOR ALL HOUSE COMMITTEES",
    "report_committee": "Committee on Oversight and Accountability",
    "report_publish_date": "April 17, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt37",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt37/pdf/CRPT-118hrpt37.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 734) TO AMEND THE EDUCATION AMENDMENTS OF 1972 TO PROVIDE THAT FOR PURPOSES OF DETERMINING COMPLIANCE WITH TITLE IX OF SUCH ACT IN ATHLETICS, SEX SHALL BE RECOGNIZED BASED SOLELY ON A PERSON'S REPRODUCTIVE BIOLOGY AND GENETICS AT BIRTH, AND PROVIDING FOR CONSIDERATION OF THE JOINT RESOLUTION (H.J. RES. 42) DISAPPROVING THE ACTION OF THE DISTRICT OF COLUMBIA COUNCIL IN APPROVING THE COMPREHENSIVE POLICING AND JUSTICE REFORM AMENDMENT ACT OF 2022",
    "report_committee": "Committee on Rules",
    "report_publish_date": "April 17, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt38",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt38/pdf/CRPT-118hrpt38.pdf",
    "report_title": "DISAPPROVING THE RULE SUBMITTED BY THE DEPARTMENT OF COMMERCE RELATING TO ``PROCEDURES COVERING SUSPENSION OF LIQUIDATION, DUTIES AND ESTIMATED DUTIES IN ACCORD WITH PRESIDENTIAL PROCLAMATION 10414''",
    "report_committee": "Committee on Ways and Means",
    "report_publish_date": "April 21, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt39",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt39/pdf/CRPT-118hrpt39.pdf",
    "report_title": "ADVANCED, LOCAL EMERGENCY RESPONSE TELECOMMUNICATIONS PARITY ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "April 24, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt40",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt40/pdf/CRPT-118hrpt40.pdf",
    "report_title": "INSTITUTE FOR TELECOMMUNICATION SCIENCES CODIFICATION ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "April 24, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt41",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt41/pdf/CRPT-118hrpt41.pdf",
    "report_title": "SECURE SPACE ACT OF 2023",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "April 25, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt42",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt42/pdf/CRPT-118hrpt42.pdf",
    "report_title": "PRECISION AGRICULTURE SATELLITE CONNECTIVITY ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "April 25, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt43",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt43/pdf/CRPT-118hrpt43.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 2811) TO PROVIDE FOR A RESPONSIBLE INCREASE TO THE DEBT CEILING, AND FOR OTHER PURPOSES, AND PROVIDING FOR CONSIDERATION OF THE JOINT RESOLUTION (H.J. RES. 39) DISAPPROVING THE RULE SUBMITTED BY THE DEPARTMENT OF COMMERCE RELATING TO ``PROCEDURES COVERING SUSPENSION OF LIQUIDATION, DUTIES AND ESTIMATED DUTIES IN ACCORD WITH PRESIDENTIAL PROCLAMATION 10414''",
    "report_committee": "Committee on Rules",
    "report_publish_date": "April 26, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt44",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt44/pdf/CRPT-118hrpt44.pdf",
    "report_title": "REQUESTING THE PRESIDENT AND DIRECTING THE SECRETARY OF DEFENSE TO TRANSMIT, RESPECTIVELY, TO THE HOUSE OF REPRESENTATIVES COPIES OF ALL DOCUMENTS INDICATING ANY PLANS FOR CURRENT OR FUTURE MILITARY ASSISTANCE TO UKRAINE AND DOCUMENTS INDICATING WHETHER ANY UNITED STATES ARMED FORCES, INCLUDING SPECIAL OPERATIONS FORCES, ARE CURRENTLY DEPLOYED IN UKRAINE",
    "report_committee": "Committee on Foreign Affairs",
    "report_publish_date": "May 2, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt45-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt45/pdf/CRPT-118hrpt45-pt1.pdf",
    "report_title": "BORDER REINFORCEMENT ACT OF 2023",
    "report_committee": "Committee on Homeland Security",
    "report_publish_date": "May 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt46-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt46/pdf/CRPT-118hrpt46-pt1.pdf",
    "report_title": "ORDERLY REQUIREMENTS DESIGNED TO ENFORCE AND REGULATE LATIN AMERICAN MIGRATION ACT",
    "report_committee": "Committee on Foreign Affairs",
    "report_publish_date": "May 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt47-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt47/pdf/CRPT-118hrpt47-pt1.pdf",
    "report_title": "BORDER SECURITY AND ENFORCEMENT ACT OF 2023",
    "report_committee": "Committee on the Judiciary",
    "report_publish_date": "May 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt48",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt48/pdf/CRPT-118hrpt48.pdf",
    "report_title": "COASTAL COMMUNITIES OCEAN ACIDIFICATION ACT OF 2023",
    "report_committee": "Committee on Science, Space, and Technology",
    "report_publish_date": "May 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt49",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt49/pdf/CRPT-118hrpt49.pdf",
    "report_title": "ADVANCED WEATHER MODEL COMPUTING DEVELOPMENT ACT",
    "report_committee": "Committee on Science, Space, and Technology",
    "report_publish_date": "May 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt50",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt50/pdf/CRPT-118hrpt50.pdf",
    "report_title": "TESTING, RAPID ANALYSIS, AND NARCOTIC QUALITY RESEARCH ACT",
    "report_committee": "Committee on Science, Space, and Technology",
    "report_publish_date": "May 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt51",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt51/pdf/CRPT-118hrpt51.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 2) TO SECURE THE BORDERS OF THE UNITED STATES, AND FOR OTHER PURPOSES, AND PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 1163) TO PROVIDE INCENTIVES FOR STATES TO RECOVER FRAUDULENTLY PAID FEDERAL AND STATE UNEMPLOYMENT COMPENSATION, AND FOR OTHER PURPOSES",
    "report_committee": "Committee on Rules",
    "report_publish_date": "May 10, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt52",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt52/pdf/CRPT-118hrpt52.pdf",
    "report_title": "9-8-8 LIFELINE CYBERSECURITY RESPONSIBILITY ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "May 11, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt53",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt53/pdf/CRPT-118hrpt53.pdf",
    "report_title": "SECURING THE BORDER FOR PUBLIC HEALTH ACT OF 2023",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "May 11, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt54",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt54/pdf/CRPT-118hrpt54.pdf",
    "report_title": "NDO FAIRNESS ACT",
    "report_committee": "Committee on the Judiciary",
    "report_publish_date": "May 15, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt55",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt55/pdf/CRPT-118hrpt55.pdf",
    "report_title": "PROTECT OUR LAW ENFORCEMENT WITH IMMIGRATION CONTROL AND ENFORCEMENT ACT OF 2023",
    "report_committee": "Committee on the Judiciary",
    "report_publish_date": "May 15, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt56",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt56/pdf/CRPT-118hrpt56.pdf",
    "report_title": "EXPRESSING SUPPORT FOR RECOGNIZING ``NATIONAL POLICE WEEK''",
    "report_committee": "Committee on the Judiciary",
    "report_publish_date": "May 15, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt57",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt57/pdf/CRPT-118hrpt57.pdf",
    "report_title": "EXPRESSING SUPPORT FOR LOCAL LAW ENFORCEMENT OFFICERS AND CONDEMNING EFFORTS TO DEFUND OR DISMANTLE LOCAL LAW ENFORCEMENT AGENCIES",
    "report_committee": "Committee on the Judiciary",
    "report_publish_date": "May 15, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt58",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt58/pdf/CRPT-118hrpt58.pdf",
    "report_title": "FEDERAL LAW ENFORCEMENT OFFICER SERVICE WEAPON PURCHASE ACT",
    "report_committee": "Committee on the Judiciary",
    "report_publish_date": "May 15, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt59",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt59/pdf/CRPT-118hrpt59.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 2494) TO MAKE THE ASSAULT OF A LAW ENFORCEMENT OFFICER A DEPORTABLE OFFENSE, AND FOR OTHER PURPOSES; PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 3091) TO ALLOW FEDERAL LAW ENFORCEMENT OFFICERS TO PURCHASE RETIRED SERVICE WEAPONS, AND FOR OTHER PURPOSES; AND PROVIDING FOR CONSIDERATION OF THE CONCURRENT RESOLUTION (H. CON. RES. 40) EXPRESSING SUPPORT FOR LOCAL LAW ENFORCEMENT OFFICERS AND CONDEMNING EFFORTS TO DEFUND OR DISMANTLE LOCAL LAW ENFORCEMENT AGENCIES",
    "report_committee": "Committee on Rules",
    "report_publish_date": "May 15, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt60",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt60/pdf/CRPT-118hrpt60.pdf",
    "report_title": "NOAA WEATHER RADIO MODERNIZATION ACT OF 2023",
    "report_committee": "Committee on Science, Space, and Technology",
    "report_publish_date": "May 16, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt61",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt61/pdf/CRPT-118hrpt61.pdf",
    "report_title": "NATIONAL WEATHER SERVICE COMMUNICATIONS IMPROVEMENT ACT",
    "report_committee": "Committee on Science, Space, and Technology",
    "report_publish_date": "May 16, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt62",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt62/pdf/CRPT-118hrpt62.pdf",
    "report_title": "MATHEMATICAL AND STATISTICAL MODELING EDUCATION ACT",
    "report_committee": "Committee on Science, Space, and Technology",
    "report_publish_date": "May 16, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt63",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt63/pdf/CRPT-118hrpt63.pdf",
    "report_title": "DETERRING AMERICA'S TECHNOLOGICAL ADVERSARIES ACT",
    "report_committee": "Committee on Foreign Affairs",
    "report_publish_date": "May 16, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt64",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt64/pdf/CRPT-118hrpt64.pdf",
    "report_title": "KOREAN AMERICAN VIETNAM ALLIES LONG OVERDUE FOR RELIEF ACT",
    "report_committee": "Committee on Veterans' Affairs",
    "report_publish_date": "May 16, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt65-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt65/pdf/CRPT-118hrpt65-pt1.pdf",
    "report_title": "PROTECTING HEALTH CARE FOR ALL PATIENTS ACT OF 2023",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "May 17, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt66-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt66/pdf/CRPT-118hrpt66-pt1.pdf",
    "report_title": "BLOCK, REPORT, AND SUSPEND SUSPICIOUS SHIPMENTS ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "May 17, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt67-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt67/pdf/CRPT-118hrpt67-pt1.pdf",
    "report_title": "HALT ALL LETHAL TRAFFICKING OF FENTANYL ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "May 17, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt68",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt68/pdf/CRPT-118hrpt68.pdf",
    "report_title": "VET-TEC AUTHORIZATION ACT OF 2023",
    "report_committee": "Committee on Veterans' Affairs",
    "report_publish_date": "May 17, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt69",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt69/pdf/CRPT-118hrpt69.pdf",
    "report_title": "PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE BUREAU OF ALCOHOL, TOBACCO, FIREARMS, AND EXPLOSIVES RELATING TO ``FACTORING CRITERIA FOR FIREARMS WITH ATTACHED `STABILIZING BRACES'''",
    "report_committee": "Committee on the Judiciary",
    "report_publish_date": "May 17, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt70",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt70/pdf/CRPT-118hrpt70.pdf",
    "report_title": "NTIA POLICY AND CYBERSECURITY COORDINATION ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "May 18, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt71",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt71/pdf/CRPT-118hrpt71.pdf",
    "report_title": "PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE DEPARTMENT OF EDUCATION RELATING TO ``WAIVERS AND MODIFICATIONS OF FEDERAL STUDENT LOANS''",
    "report_committee": "Committee on Education and the Workforce",
    "report_publish_date": "May 18, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt72",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt72/pdf/CRPT-118hrpt72.pdf",
    "report_title": "CHINA FINANCIAL THREAT MITIGATION ACT OF 2023",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "May 22, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt73",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt73/pdf/CRPT-118hrpt73.pdf",
    "report_title": "BLOCK GRANT ASSISTANCE ACT OF 2023",
    "report_committee": "Committee on Agriculture",
    "report_publish_date": "May 22, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt74-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt74/pdf/CRPT-118hrpt74-pt1.pdf",
    "report_title": "DOE AND USDA INTERAGENCY RESEARCH ACT",
    "report_committee": "Committee on Science, Space, and Technology",
    "report_publish_date": "May 22, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt74-pt2",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt74/pdf/CRPT-118hrpt74-pt2.pdf",
    "report_title": "DOE AND USDA INTERAGENCY RESEARCH ACT",
    "report_committee": "Committee on Agriculture",
    "report_publish_date": "May 22, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt75-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt75/pdf/CRPT-118hrpt75-pt1.pdf",
    "report_title": "PREVENTING THE FINANCING OF ILLEGAL SYNTHETIC DRUGS ACT",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "May 22, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt76",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt76/pdf/CRPT-118hrpt76.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 467) TO AMEND THE CONTROLLED SUBSTANCES ACT WITH RESPECT TO THE SCHEDULING OF FENTANYL- RELATED SUBSTANCES, AND FOR OTHER PURPOSES; PROVIDING FOR CONSIDERATION OF THE JOINT RESOLUTION (S.J. RES. 11) PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE ENVIRONMENTAL PROTECTION AGENCY RELATING TO ``CONTROL OF AIR POLLUTION FROM NEW MOTOR VEHICLES: HEAVY DUTY ENGINE AND VEHICLE STANDARDS''; AND PROVIDING FOR CONSIDERATION OF THE JOINT RESOLUTION (H.J. RES. 45) PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE DEPARTMENT OF EDUCATION RELATING TO ``WAIVERS AND MODIFICATIONS OF FEDERAL STUDENT",
    "report_committee": "Committee on Rules",
    "report_publish_date": "May 22, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt77",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt77/pdf/CRPT-118hrpt77.pdf",
    "report_title": "EQUAL OPPORTUNITY FOR ALL INVESTORS ACT OF 2023",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "May 30, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt78",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt78/pdf/CRPT-118hrpt78.pdf",
    "report_title": "PROMOTING OPPORTUNITIES FOR NON-TRADITIONAL CAPITAL FORMATION ACT",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "May 30, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt79",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt79/pdf/CRPT-118hrpt79.pdf",
    "report_title": "ENHANCING MULTI-CLASS SHARE DISCLOSURES ACT",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "May 30, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt80",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt80/pdf/CRPT-118hrpt80.pdf",
    "report_title": "TO REQUIRE THE SECURITIES AND EXCHANGE COMMISSION TO CARRY OUT A STUDY AND RULEMAKING ON THE DEFINITION OF THE TERM ``SMALL ENTITY'' FOR PURPOSES OF THE SECURITIES LAWS, AND FOR OTHER PURPOSES",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "May 30, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt81",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt81/pdf/CRPT-118hrpt81.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 3746) TO PROVIDE FOR A RESPONSIBLE INCREASE TO THE DEBT CEILING",
    "report_committee": "Committee on Rules",
    "report_publish_date": "May 30, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt82",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt82/pdf/CRPT-118hrpt82.pdf",
    "report_title": "AUTHORIZING THE USE OF THE CAPITOL GROUNDS FOR THE GREATER WASHINGTON SOAP BOX DERBY",
    "report_committee": "Committee on Transportation and Infrastructure",
    "report_publish_date": "May 31, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt83",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt83/pdf/CRPT-118hrpt83.pdf",
    "report_title": "SEPARATION OF POWERS RESTORATION ACT OF 2023",
    "report_committee": "Committee on the Judiciary",
    "report_publish_date": "June 1, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt84-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt84/pdf/CRPT-118hrpt84-pt1.pdf",
    "report_title": "REGULATIONS FROM THE EXECUTIVE IN NEED OF SCRUTINY ACT OF 2023",
    "report_committee": "Committee on the Judiciary",
    "report_publish_date": "June 1, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt85",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt85/pdf/CRPT-118hrpt85.pdf",
    "report_title": "GAS STOVE PROTECTION AND FREEDOM ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "June 1, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt86",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt86/pdf/CRPT-118hrpt86.pdf",
    "report_title": "SAVE OUR GAS STOVES ACT",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "June 1, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt87",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt87/pdf/CRPT-118hrpt87.pdf",
    "report_title": "FAIR INVESTMENT OPPORTUNITIES FOR PROFESSIONAL EXPERTS ACT",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt88",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt88/pdf/CRPT-118hrpt88.pdf",
    "report_title": "TO AMEND THE FEDERAL SECURITIES LAWS TO SPECIFY THE PERIODS FOR WHICH FINANCIAL STATEMENTS ARE REQUIRED TO BE PROVIDED BY AN EMERGING GROWTH COMPANY, AND FOR OTHER PURPOSES",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt89",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt89/pdf/CRPT-118hrpt89.pdf",
    "report_title": "TO AMEND THE SECURITIES EXCHANGE ACT OF 1934 TO SPECIFY CERTAIN REGISTRATION STATEMENT CONTENTS FOR EMERGING GROWTH COMPANIES, TO PERMIT ISSUERS TO FILE DRAFT REGISTRATION STATEMENTS WITH THE SECURITIES AND EXCHANGE COMMISSION FOR CONFIDENTIAL REVIEW, AND FOR OTHER PURPOSES",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt90",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt90/pdf/CRPT-118hrpt90.pdf",
    "report_title": "ENCOURAGING PUBLIC OFFERINGS ACT OF 2023",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt91",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt91/pdf/CRPT-118hrpt91.pdf",
    "report_title": "MIDDLE MARKET IPO UNDERWRITING COST ACT",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt92",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt92/pdf/CRPT-118hrpt92.pdf",
    "report_title": "ACCREDITED INVESTOR DEFINITION REVIEW ACT",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt93",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt93/pdf/CRPT-118hrpt93.pdf",
    "report_title": "SENIOR SECURITY ACT OF 2023",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt94",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt94/pdf/CRPT-118hrpt94.pdf",
    "report_title": "PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE UNITED STATES FISH AND WILDLIFE SERVICE RELATING TO ``ENDANGERED AND THREATENED WILDLIFE AND PLANTS; LESSER PRAIRIE-CHICKEN; THREATENED STATUS WITH SECTION 4(D) RULE FOR THE NORTHERN DISTINCT POPULATION SEGMENT AND ENDANGERED STATUS FOR THE SOUTHERN DISTINCT POPULATION SEGMENT''",
    "report_committee": "Committee on Natural Resources",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt95",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt95/pdf/CRPT-118hrpt95.pdf",
    "report_title": "PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE NATIONAL MARINE FISHERIES SERVICE RELATING TO ``ENDANGERED AND THREATENED WILDLIFE AND PLANTS; REGULATIONS FOR LISTING ENDANGERED AND THREATENED SPECIES AND DESIGNATING CRITICAL HABITAT''",
    "report_committee": "Committee on Natural Resources",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt96",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt96/pdf/CRPT-118hrpt96.pdf",
    "report_title": "PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE UNITED STATES FISH AND WILDLIFE SERVICE RELATING TO ``ENDANGERED AND THREATENED WILDLIFE AND PLANTS; ENDANGERED SPECIES STATUS FOR NORTHERN LONG-EARED BAT''",
    "report_committee": "Committee on Natural Resources",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt97",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt97/pdf/CRPT-118hrpt97.pdf",
    "report_title": "SMALL BUSINESS WORKFORCE PIPELINE ACT OF 2023",
    "report_committee": "Committee on Small Business",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt98",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt98/pdf/CRPT-118hrpt98.pdf",
    "report_title": "VETERAN ENTREPRENEURSHIP TRAINING ACT OF 2023",
    "report_committee": "Committee on Small Business",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt99",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt99/pdf/CRPT-118hrpt99.pdf",
    "report_title": "7(A) LOAN AGENT OVERSIGHT ACT",
    "report_committee": "Committee on Small Business",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt100",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt100/pdf/CRPT-118hrpt100.pdf",
    "report_title": "SMALL BUSINESS 7(A) LOAN AGENT TRANSPARENCY ACT",
    "report_committee": "Committee on Small Business",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt101",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt101/pdf/CRPT-118hrpt101.pdf",
    "report_title": "SUPPORTING SMALL BUSINESS AND CAREER AND TECHNICAL EDUCATION ACT OF 2023",
    "report_committee": "Committee on Small Business",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt102",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt102/pdf/CRPT-118hrpt102.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 277) TO AMEND CHAPTER 8 OF TITLE 5, UNITED STATES CODE, TO PROVIDE THAT MAJOR RULES OF THE EXECUTIVE BRANCH SHALL HAVE NO FORCE OR EFFECT UNLESS A JOINT RESOLUTION OF APPROVAL IS ENACTED INTO LAW; PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 288) TO AMEND TITLE 5, UNITED STATES CODE, TO CLARIFY THE NATURE OF JUDICIAL REVIEW OF AGENCY INTERPRETATIONS OF STATUTORY AND REGULATORY PROVISIONS; PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 1615) TO PROHIBIT THE USE OF FEDERAL FUNDS TO BAN GAS STOVES; AND PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 1640) TO PROHIBIT THE SECRETARY OF ENERGY FROM FINALIZING, IMPLEMENTING, OR ENFORCING THE PROPOSED RULE TITLED ``ENERGY CONSERVATION PROGRAM: ENERGY CONSERVATION S",
    "report_committee": "Committee on Rules",
    "report_publish_date": "June 5, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt103",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt103/pdf/CRPT-118hrpt103.pdf",
    "report_title": "MIDDLE CLASS BORROWER PROTECTION ACT OF 2023",
    "report_committee": "Committee on Financial Services",
    "report_publish_date": "June 7, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt104",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt104/pdf/CRPT-118hrpt104.pdf",
    "report_title": "ANIMAL DRUG AND ANIMAL GENERIC DRUG USER FEE AMENDMENTS OF 2023",
    "report_committee": "Committee on Energy and Commerce",
    "report_publish_date": "June 9, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt105",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt105/pdf/CRPT-118hrpt105.pdf",
    "report_title": "TO PROVIDE FOR THE EXTENSION OF TAXES FUNDING THE AIRPORT AND AIRWAY TRUST FUND AND TO REQUIRE THE DESIGNATION OF CERTAIN AIRPORTS AS PORTS OF ENTRY",
    "report_committee": "Committee on Ways and Means",
    "report_publish_date": "June 12, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt106",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt106/pdf/CRPT-118hrpt106.pdf",
    "report_title": "SMALL BUSINESS FLEXIBILITY ACT",
    "report_committee": "Committee on Ways and Means",
    "report_publish_date": "June 12, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt107",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt107/pdf/CRPT-118hrpt107.pdf",
    "report_title": "CUSTOM HEALTH OPTION AND INDIVIDUAL CARE EXPENSE ARRANGEMENT ACT",
    "report_committee": "Committee on Ways and Means",
    "report_publish_date": "June 12, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt108",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt108/pdf/CRPT-118hrpt108.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE JOINT RESOLUTION (H.J. RES. 44) PROVIDING FOR CONGRESSIONAL DISAPPROVAL UNDER CHAPTER 8 OF TITLE 5, UNITED STATES CODE, OF THE RULE SUBMITTED BY THE BUREAU OF ALCOHOL, TOBACCO, FIREARMS, AND EXPLOSIVES RELATING TO ``FACTORING CRITERIA FOR FIREARMS WITH ATTACHED `STABILIZING BRACES'''; PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 277) TO AMEND CHAPTER 8 OF TITLE 5, UNITED STATES CODE, TO PROVIDE THAT MAJOR RULES OF THE EXECUTIVE BRANCH SHALL HAVE NO FORCE OR EFFECT UNLESS A JOINT RESOLUTION OF APPROVAL IS ENACTED INTO LAW; PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 288) TO AMEND TITLE 5, UNITED STATES CODE, TO CLARIFY THE NATURE OF JUDICIAL REVIEW OF AGENCY INTERPRETATIONS OF STATUTORY AND REGULATORY PROVISI",
    "report_committee": "Committee on Rules",
    "report_publish_date": "June 12, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt109",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt109/pdf/CRPT-118hrpt109.pdf",
    "report_title": "TELEHEALTH EXPANSION ACT OF 2023",
    "report_committee": "Committee on Ways and Means",
    "report_publish_date": "June 13, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt110",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt110/pdf/CRPT-118hrpt110.pdf",
    "report_title": "PAPERWORK BURDEN REDUCTION ACT",
    "report_committee": "Committee on Ways and Means",
    "report_publish_date": "June 13, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt111",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt111/pdf/CRPT-118hrpt111.pdf",
    "report_title": "EMPLOYER REPORTING IMPROVEMENT ACT",
    "report_committee": "Committee on Ways and Means",
    "report_publish_date": "June 13, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt112",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt112/pdf/CRPT-118hrpt112.pdf",
    "report_title": "ASSOCIATION HEALTH PLANS ACT",
    "report_committee": "Committee on Education and the Workforce",
    "report_publish_date": "June 14, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt113",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt113/pdf/CRPT-118hrpt113.pdf",
    "report_title": "CONDEMNING THE USE OF ELEMENTARY AND SECONDARY SCHOOL FACILITIES TO PROVIDE SHELTER FOR ALIENS WHO ARE NOT ADMITTED TO THE UNITED STATES",
    "report_committee": "Committee on Education and the Workforce",
    "report_publish_date": "June 14, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt114-pt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt114/pdf/CRPT-118hrpt114-pt1.pdf",
    "report_title": "SELF-INSURANCE PROTECTION ACT",
    "report_committee": "Committee on Education and the Workforce",
    "report_publish_date": "June 20, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt115",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt115/pdf/CRPT-118hrpt115.pdf",
    "report_title": "PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 3564) TO CANCEL RECENT CHANGES MADE BY THE FEDERAL HOUSING FINANCE AGENCY TO THE UP FRONT LOAN LEVEL PRICING ADJUSTMENTS CHARGED BY FANNIE MAE AND FREDDIE MAC FOR GUARANTEE OF SINGLE FAMILY MORTGAGES, AND FOR OTHER PURPOSES; PROVIDING FOR CONSIDERATION OF THE BILL (H.R. 3799) TO AMEND THE INTERNAL REVENUE CODE OF 1986 TO PROVIDE FOR HEALTH REIMBURSEMENT ARRANGEMENTS INTEGRATED WITH INDIVIDUAL HEALTH INSURANCE COVERAGE; AND PROVIDING FOR CONSIDERATION OF THE RESOLUTION (H. RES. 461) CONDEMNING THE USE OF ELEMENTARY AND SECONDARY SCHOOL FACILITIES TO PROVIDE SHELTER FOR ALIENS WHO ARE NOT ADMITTED TO THE UNITED STATES",
    "report_committee": "Committee on Rules",
    "report_publish_date": "June 20, 2023"
  },
  {
    "chamber": "hrpt",
    "report_id": "CRPT-118hrpt117",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118hrpt117/pdf/CRPT-118hrpt117.pdf",
    "report_title": "RELATING TO THE RESOLUTION (H. RES. 503) IMPEACHING JOSEPH R. BIDEN, JR., PRESIDENT OF THE UNITED STATES, FOR HIGH CRIMES AND MISDEMEANORS",
    "report_committee": "Committee on Rules",
    "report_publish_date": "June 21, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt1",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt1/pdf/CRPT-118srpt1.pdf",
    "report_title": "ACTIVITIES OF THE COMMITTEE ON HOMELAND SECURITY AND GOVERNMENTAL AFFAIRS",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "March 1, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt2",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt2/pdf/CRPT-118srpt2.pdf",
    "report_title": "PALA BAND OF MISSION INDIANS LAND TRANSFER ACT OF 2023",
    "report_committee": "Committee on Indian Affairs",
    "report_publish_date": "March 21, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt3",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt3/pdf/CRPT-118srpt3.pdf",
    "report_title": "LEGISLATIVE AND OVERSIGHT ACTIVITIES DURING THE 117TH CONGRESS BY THE SENATE COMMITTEE ON VETERANS' AFFAIRS",
    "report_committee": "Committee on Veterans' Affairs",
    "report_publish_date": "March 27, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt4",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt4/pdf/CRPT-118srpt4.pdf",
    "report_title": "REPORT ON THE ACTIVITIES OF THE COMMITTEE ON FINANCE OF THE UNITED STATES SENATE DURING THE 117th CONGRESS PURSUANT TO Rule XXVI of the Standing Rules OF THE UNITED STATES SENATE",
    "report_committee": "Committee on Finance",
    "report_publish_date": "March 28, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt5",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt5/pdf/CRPT-118srpt5.pdf",
    "report_title": "REPORT OF THE SELECT COMMITTEE ON INTELLIGENCE UNITED STATES SENATE COVERING THE PERIOD JANUARY 3, 2021 TO JANUARY 3, 2023",
    "report_committee": "Select Committee on Intelligence",
    "report_publish_date": "March 28, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt6",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt6/pdf/CRPT-118srpt6.pdf",
    "report_title": "HISTORY, JURISDICTION, AND A SUMMARY OF ACTIVITIES OF THE COMMITTEE ON ENERGY AND NATURAL RESOURCES DURING THE 117TH CONGRESS",
    "report_committee": "Committee on Energy and Natural Resources",
    "report_publish_date": "March 29, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt7",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt7/pdf/CRPT-118srpt7.pdf",
    "report_title": "Report on Legislative Activities of the Committee on Health, Education, Labor, and Pensions",
    "report_committee": "Committee on Health, Education, Labor, and Pensions",
    "report_publish_date": "March 29, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt8",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt8/pdf/CRPT-118srpt8.pdf",
    "report_title": "REVIEW OF LEGISLATIVE ACTIVITY DURING THE 117TH CONGRESS",
    "report_committee": "Committee on Rules and Administration",
    "report_publish_date": "April 17, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt9",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt9/pdf/CRPT-118srpt9.pdf",
    "report_title": "TO AMEND THE NATIVE AMERICAN TOURISM AND IMPROVING VISITOR EXPERIENCE ACT TO AUTHORIZE GRANTS TO INDIAN TRIBES, TRIBAL ORGANIZATIONS, AND NATIVE HAWAIIAN ORGANIZATIONS, AND FOR OTHER PURPOSES",
    "report_committee": "Committee on Indian Affairs",
    "report_publish_date": "April 18, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt10",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt10/pdf/CRPT-118srpt10.pdf",
    "report_title": "REPORT ON THE ACTIVITIES OF THE COMMITTEE ON BANKING, HOUSING, AND URBAN AFFAIRS OF THE UNITED STATES SENATE DURING THE 117th CONGRESS PURSUANT TO Rule XXVI of the Standing Rules OF THE UNITED STATES SENATE",
    "report_committee": "Committee on Banking, Housing, and Urban Affairs",
    "report_publish_date": "April 18, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt11",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt11/pdf/CRPT-118srpt11.pdf",
    "report_title": "REPORT ON THE ACTIVITIES OF THE COMMITTEE ON THE JUDICIARY UNITED STATES SENATE DURING THE ONE HUNDRED SEVENTEENTH CONGRESS PURSUANT TO Rule XXVI of the Standing Rules of the United States Senate",
    "report_committee": "Committee on the Judiciary",
    "report_publish_date": "April 19, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt12",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt12/pdf/CRPT-118srpt12.pdf",
    "report_title": "LOBBYING DISCLOSURE IMPROVEMENT ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "April 19, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt13",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt13/pdf/CRPT-118srpt13.pdf",
    "report_title": "DISCLOSING FOREIGN INFLUENCE IN LOBBYING ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "April 25, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt14",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt14/pdf/CRPT-118srpt14.pdf",
    "report_title": "MILITARY SPOUSE EMPLOYMENT ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "April 27, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt15",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt15/pdf/CRPT-118srpt15.pdf",
    "report_title": "FEDERAL DATA CENTER ENHANCEMENT ACT OF 2023",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "April 27, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt16",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt16/pdf/CRPT-118srpt16.pdf",
    "report_title": "END FENTANYL ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 4, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt17",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt17/pdf/CRPT-118srpt17.pdf",
    "report_title": "IMPACTT HUMAN TRAFFICKING ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 4, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt18",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt18/pdf/CRPT-118srpt18.pdf",
    "report_title": "FEDERAL AGENCY PERFORMANCE ACT OF 2023",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 4, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt19",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt19/pdf/CRPT-118srpt19.pdf",
    "report_title": "GUIDANCE CLARITY ACT OF 2023",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 9, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt20",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt20/pdf/CRPT-118srpt20.pdf",
    "report_title": "NATIONAL RISK MANAGEMENT ACT OF 2023",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 9, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt21",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt21/pdf/CRPT-118srpt21.pdf",
    "report_title": "LEGISLATIVE ACTIVITIES REPORT OF THE COMMITTEE ON COMMERCE, SCIENCE, AND TRANSPORTATION OF THE UNITED STATES SENATE during the ONE HUNDRED SEVENTEENTH CONGRESS Pursuant to Rule XXVI of the Standing Rules of the United States Senate",
    "report_committee": "Committee on Commerce, Science, and Transportation",
    "report_publish_date": "May 10, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt22",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt22/pdf/CRPT-118srpt22.pdf",
    "report_title": "CHANGING AGE-DETERMINED ELIGIBILITY TO STUDENT INCENTIVE PAYMENTS ACT",
    "report_committee": "Committee on Commerce, Science, and Transportation",
    "report_publish_date": "May 10, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt23",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt23/pdf/CRPT-118srpt23.pdf",
    "report_title": "TO REQUIRE THE COMMISSIONER OF U.S. CUSTOMS AND BORDER PROTECTION TO ESTABLISH PROCEDURES FOR CONDUCTING MAINTENANCE PROJECTS AT PORTS OF ENTRY AT WHICH THE OFFICE OF FIELD OPERATIONS CONDUCTS CERTAIN ENFORCEMENT AND FACILITATION ACTIVITIES",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 11, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt24",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt24/pdf/CRPT-118srpt24.pdf",
    "report_title": "DISASTER CONTRACTING IMPROVEMENT ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 11, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt25",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt25/pdf/CRPT-118srpt25.pdf",
    "report_title": "GAO DATABASE MODERNIZATION ACT OF 2023",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 11, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt26",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt26/pdf/CRPT-118srpt26.pdf",
    "report_title": "CLEAR AND CONCISE CONTENT ACT OF 2023",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 11, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt27",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt27/pdf/CRPT-118srpt27.pdf",
    "report_title": "CUSTOMS TRADE PARTNERSHIP AGAINST TERRORISM (CTPAT) PILOT PROGRAM ACT OF 2023",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 11, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt28",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt28/pdf/CRPT-118srpt28.pdf",
    "report_title": "PROVIDING ACCOUNTABILITY THROUGH TRANSPARENCY ACT OF 2023",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 16, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt29",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt29/pdf/CRPT-118srpt29.pdf",
    "report_title": "BANNING OPERATIONS AND LEASES WITH THE ILLEGITIMATE VENEZUELAN AUTHORITARIAN REGIME ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 16, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt30",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt30/pdf/CRPT-118srpt30.pdf",
    "report_title": "FIRE SUPPRESSION AND RESPONSE FUNDING ASSURANCE ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 16, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt31",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt31/pdf/CRPT-118srpt31.pdf",
    "report_title": "DUPLICATION SCORING ACT OF 2023",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 16, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt32",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt32/pdf/CRPT-118srpt32.pdf",
    "report_title": "SECURING OPEN SOURCE SOFTWARE ACT OF 2023",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 16, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt33",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt33/pdf/CRPT-118srpt33.pdf",
    "report_title": "TRIBAL TRUST LAND HOMEOWNERSHIP ACT OF 2023",
    "report_committee": "Committee on Indian Affairs",
    "report_publish_date": "May 30, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt34",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt34/pdf/CRPT-118srpt34.pdf",
    "report_title": "URBAN INDIAN HEALTH CONFER ACT",
    "report_committee": "Committee on Indian Affairs",
    "report_publish_date": "May 30, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt35",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt35/pdf/CRPT-118srpt35.pdf",
    "report_title": "SMART LEASING ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 30, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt36",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt36/pdf/CRPT-118srpt36.pdf",
    "report_title": "IDENTIFYING AND ELIMINATING WASTEFUL PROGRAMS ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 30, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt37",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt37/pdf/CRPT-118srpt37.pdf",
    "report_title": "NO CORRUPTION ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "May 30, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt38",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt38/pdf/CRPT-118srpt38.pdf",
    "report_title": "A BILL TO AMEND THE INDIAN SELF-DETERMINATION AND EDUCATION ASSISTANCE ACT TO EXTEND THE DEADLINE FOR THE SECRETARY OF THE INTERIOR TO PROMULGATE REGULATIONS IMPLEMENTING TITLE IV OF THAT ACT, AND FOR OTHER PURPOSES",
    "report_committee": "Committee on Indian Affairs",
    "report_publish_date": "June 8, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt39",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt39/pdf/CRPT-118srpt39.pdf",
    "report_title": "DISASTER ASSISTANCE SIMPLIFICATION ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "June 13, 2023"
  },
  {
    "chamber": "srpt",
    "report_id": "CRPT-118srpt40",
    "report_pdf_url": "https://www.govinfo.gov/content/pkg/CRPT-118srpt40/pdf/CRPT-118srpt40.pdf",
    "report_title": "CONGRESSIONAL BUDGET OFFICE DATA ACCESS ACT",
    "report_committee": "Committee on Homeland Security and Governmental Affairs",
    "report_publish_date": "June 13, 2023"
  }
]